import { mapState } from "vuex";

export const tagmanager = {
  methods: {
    gtmAddToCart(item,qty) {
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: this.activeCart.paymentCurrency,
          items: [
            {
              item_id: item.baseSku,
              item_name: item.productTitle,
              price: item.unitPriceRaw.toFixed(2),
              item_variant: item.sku,
              discount: item.unitDiscountValueRaw, // Optional
              quantity: qty,
            },
          ],
        },
      });
    },
    gtmRemoveFromCart(item, qty){
      window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
          currency: this.activeCart.paymentCurrency,
          items: [
            {
              item_id: item.baseSku,
              item_name: item.productTitle,
              price: item.unitPriceRaw.toFixed(2),
              item_variant: item.sku,
              discount: item.unitDiscountValueRaw, // Optional
              quantity: qty,
            },
          ],
        },
      });

    },
   gtmViewItem(item){
      /** Push productview to GA4 **/
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          currency: this.activeCart.paymentCurrency,
          items: [
            {
              item_id: item.baseSku,
              item_name: item.productTitle,
              price: item.salePriceRaw.toFixed(2),
            },
          ],
        },
      });

   },
   gtmViewCart(){
      if(!window.dataLayer){
        return;
      }

      window.dataLayer.push({
        event: "view_cart",
        ecommerce: {
          currency: this.activeCart.paymentCurrency,
          items: this.gtmGetItems(this.activeCart),
        },
      });

   },
   gtmBeginCheckout(){
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: this.activeCart.paymentCurrency,
        items: this.gtmGetItems(this.activeCart),
      },
    });
   },
   gtmPurchase(order){
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        currency: order.paymentCurrency,
        transaction_id: order.reference,
        value: order.priceBasketTotalRaw, // The total cart value
        shipping: order.shippingCostRaw, // The shipping amount paid
        tax: order.vatRaw, // The amount of tax paid
        coupon: order.couponCode, // Optional
        items: this.gtmGetItems(order),
      },
    });
   },
   gtmAddShippingInfo(){
    window.dataLayer.push({
      event: "add_shipping_info",
      ecommerce: {
        currency: this.activeCart.paymentCurrency,
        items: this.gtmGetItems(this.activeCart),
      },
    });
   },
   gtmGetItems(order){
    let items = [];
    let lineItems = order.lineItems;
    Object.keys(lineItems).forEach(key => {
      items.push({
        item_name: lineItems[key]['title'],
        item_id: lineItems[key]['baseSku'],
        item_variant: lineItems[key]['sku'],
        price: lineItems[key]['unitPriceRaw'],
        quantity: lineItems[key]['qty'],
        discount: lineItems[key]['unitDiscountValueRaw'],
      });
    });
    return items;
   }
  } ,
  computed: {
    ...mapState({
      activeCart: (state) => state.cart.cart,
    }),
  },
};
