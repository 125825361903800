import { createStore, createLogger } from 'vuex'
import { tagmanager } from "./mixins/tagmanager";
import VuexPersistence from 'vuex-persist'

//Modules
import app from './modules/app'
import cart from './modules/cart'

const debug = process.env.NODE_ENV !== 'production'

const vuexLocal = new VuexPersistence<RootState>({
	storage: window.sessionStorage
})

export default createStore({
	modules: {
		app,
		cart
	},
	strict: debug,
	plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin]
})
