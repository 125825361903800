import axios from "axios";

export const punchout = {
	data: () => ({
      doPunchout: false,
	}),
  methods: {
    punchOut() {
      if (this.doPunchout) {
        return;
      }

      this.doPunchout = true;
      const formData = new FormData();
      formData.append("action", this.actions.punchOut);
      formData.append("CRAFT_CSRF_TOKEN", this.csrf);

      axios
        .post(document.location.href, formData)
        .then((response) => {
          let data = response.data;
          this.sendToCustomer(data.hookUrl, data.formData, data.target);
        })
        .catch((error) => {
          this.doPunchout = false;
        });
    },
    sendToCustomer(path, params, target = "_self") {
      const form = document.createElement("form");
      form.method = "post";
      form.action = path;
      form.target = target;

      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.name = key;
          hiddenField.value = params[key];
          form.appendChild(hiddenField);
        }
      }

      // //Set charset
      // form.acceptCharset = 'ISO-8859-1'

      document.body.appendChild(form);

      if(path){
        form.submit();
        return;
      }
      this.doPunchout = false;
    },
  } ,
};
