const state = () => ({
  scrollPosition: 0,
  csrfToken: "",
  isMapVisible: false,
  translations: [],
  siteUrl: "",
  isVideoOpen: false,
  videoId: "",
  isMobileMenuOpen: false,
  showContactperson: false,
  showCallMe: false,
  isSiteSelectorOpen: false,
  favorites: [],
  impersonate: false,
  userToken: "",
});

// Getters
const getters = {
  scrollPosition: (state, getters) => {
    return state.scrollPosition;
  },
};

// Actions
const actions = {
  showVideo({ commit, state }, videoId) {
    commit("setVideoId", videoId);
    commit("setVideoVisibility", true);
  },
  closeVideo({ commit, state }) {
    commit("setVideoId", "");
    commit("setVideoVisibility", false);
  },
  openSiteSelector({ commit, state }) {
    commit("setSiteSelectorState", true);
  },
  closeSiteSelector({ commit, state }) {
    commit("setSiteSelectorState", false);
  },
};

// Mutations
const mutations = {
  setScrollPosition(state, position) {
    state.scrollPosition = position;
  },
  setCsrfToken(state, token) {
    state.csrfToken = token;
  },
  setTranslations(state, value) {
    state.translations = value;
  },
  setSiteUrl(state, value) {
    state.siteUrl = value;
  },
  setHover(state, value) {
    state.megahover = value;
  },
  setVideoId(state, value) {
    state.videoId = value;
  },
  setVideoVisibility(state, value) {
    state.isVideoOpen = value;
  },
  setMobilemenuState(state, value) {
    state.isMobileMenuOpen = value;
  },
  setContactpersonState(state, value) {
    state.showContactperson = value;
  },
  setCallMeState(state, value) {
    state.showCallMe = value;
  },
  setSiteSelectorState(state, value) {
    state.isSiteSelectorOpen = value;
  },
  setFavorites(state, value) {
    state.favorites = value;
  },
  setCallMeState(state, value) {
    state.showCallMe = value;
  },
  setImpersonateState(state, value) {
    state.impersonate = value;
  },
  setUserTokenState(state, value) {
    state.userToken = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
