const state = () => ({
  cart: [],
  qty: 0,
  isVisible: false,
});

// Getters
const getters = {
  showQty: (state, getters) => {
    if (state.qty > 0) {
      return true;
    }

    return false;
  },
};

// Actions
const actions = {
  updateCart({ commit, state }, cart) {
    commit("setCart", cart);
    commit("setQty", cart.totalQty);
  },
  open({ commit, state }) {
    commit("setBasketVisibility", true);
  },
  close({ commit, state }) {
    commit("setBasketVisibility", false);
  },
};

// Mutations
const mutations = {
  setQty(state, value) {
    state.qty = value;
  },
  setCart(state, value) {
    // Vue.set(state, 'cart', value)
    state.cart = value;
  },
  setBasketVisibility(state, value) {
    state.isVisible = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
